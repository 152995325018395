<template>
  <div :class="[containerStyle, 'select__container']">
    <div :class="[inputWrapperStyle, 'select__input-wrapper']">
      <label :for="id" class="select__label">
        {{ label }}
      </label>

      <select
        v-bind="$attrs"
        @change="handleInput"
        @invalid="checkValidity"
        :id="id"
        :disabled="isReadOnly"
        :class="[inputStyle, 'select__input']"
        ref="select">
        <option
          :selected="value === 'NotSet' || (value && !optionList.find(option => option.value === value)) || !value"
          :label="placeholder || $t('common.select')"
          value=""
          defaultSelected
          disabled>
        </option>
        <option
          v-for="(option, index) in optionList"
          :selected="option.value === value"
          :label="$t(option.label) ? $t(option.label) : option.label"
          :value="option.value"
          :key="`select-option-${option}-${index}`">
        </option>
      </select>

      <Icon
        :variant="'arrow-down-outline'"
        :color="isReadOnly ? 'neutral' : 'black'"
        :size="'xs'"
        class="select__arrow">
      </Icon>
    </div>

    <!-- @slot Custom error  -->
    <slot name="error">
      <span
        v-if="localErrors.length"
        :title="localErrors[0].message"
        class="select__error-message">
        {{ localErrors[0].message }}
      </span>
    </slot>
  </div>
</template>

<script>

import Icon                         from '../Icon/a-Icon.vue';
import { handleValidityCheck }      from '../../../utils/DOMUtils.js';


export default {
  name: 'a-Select',
  inheritAttrs: false,
  components: { Icon },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      default: () => ([])
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderColor: {
      type: String,
      default: 'grey',
      validator: color => ['grey', 'black'].includes(color)
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
    isAutofocus: {
      type: Boolean,
      default: () => false,
    },
    isResetValueAfterSelect: {
      type: Boolean,
      default: () => false,
    },
    isGhost: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    localErrors: [],
  }),
  computed:  {
    optionList() {
      return this.options.map((option) => {
        return (typeof option === 'string') || (option instanceof String)
          ? { value: option, label: option }
          : option
      });
    },
    containerStyle() {
      return { [`select__container--${this.localErrors[0]?.type}`]: this.localErrors.length }
    },
    inputWrapperStyle() {
      return { 'select__input-wrapper--ghost': this.isGhost };
    },
    inputStyle() {
      return {
        [`select__input--${this.placeholderColor}`]: !!this.placeholder,
        'select__input--checked': Boolean(this.value),
      };
    }
  },
  mounted() {
    if (this.isAutofocus) this.$refs.select?.focus();
  },
  methods: {
    handleValidityCheck,
    handleInput(event) {
      this.checkValidity();
      this.$emit('change', event.target.value);

      if (this.isResetValueAfterSelect) this.$refs.select.selectedIndex = 0;
    },
    checkValidity() {
      const errors = this.handleValidityCheck(this.$refs.select);

      this.localErrors = errors.length ? [ ...errors, ...this.localErrors ] :  [];
    },
  }
}

</script>

<style lang="scss">
.select {
  &__container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    font-family: var(--font-stack-secondary);

    &:focus-within {
      .select__input {
        border: 1px solid var(--color-black);
      }
    }

    $levels: "valid", "warning", "alert";

    @each $level in $levels {
      &--#{$level} {
        color: var(--color-#{$level});
      }
    }
  }

  &__input-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: var(--space-main-height);

    &--ghost {
      background-color: transparent;
    }
  }

  &__label {
    margin-bottom: var(--space-xs);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    line-height: var(--lineheight-md);
    color: var(--color-black);
  }

  &__input {
    flex: 1 0 auto;
    height: var(--space-main-height);
    padding: 0 var(--space-base);
    padding-right: var(--space-lg);
    font-weight: var(--font-medium);
    font-size: var(--text-xs);
    font-style: italic;
    line-height: var(--lineheight-lg);
    border: 1px solid var(--color-grey-semi);
    border-radius: var(--rounded-xs);
    color: var(--color-black);
    background-color: transparent;

    @include textOverflowEllipsis();

    @media screen and ($desktop) {
      padding-right: var(--space-xl);
    }

    &:focus {
      outline: none;
      border-width: 2px !important;
    }

    &:not(:checked) {
      font-size: var(--text-xxs);
      font-style: italic;
      border-color: var(--color-black);
      color: var(--color-grey-neutral);
    }

    &:disabled {
      border-color: var(--color-grey-neutral) !important;
      color: var(--color-grey-neutral) !important;
      cursor: not-allowed;

      + .select__arrow {
        color: var(--color-grey-neutral) !important;
      }
    }

    &--checked {
      font-size: var(--text-xs) !important;
      font-style: normal !important;
      border-color: var(--color-black) !important;
      color: var(--color-black) !important;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: var(--space-sm);
    pointer-events: none;
    transform: translateY(60%) translateX(-5px);
  }

  &__error-message {
    margin-top: var(--space-xxs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xxs);
    font-weight: 600;
    line-height: var(--lineheight-xs);
  }
}
</style>
